<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat style="user-select: none">
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.images')" :memo="this.phase === 'recall' ? time : -1" ref="header"/>

    <!-- Pagination -->
    <Pagination :page="page" :pages="pages" :on-page-change="onPageChange"
                v-if="phase !== 'recall'"/>

    <!-- Game: memo -->
    <div id="memo" v-if="phase === 'memo'" class="d-flex flex-column flex-nowrap justify-center align-start">
      <div v-for="row in memoRows[page - 1]" :key="page + '-' + row.number"
           class="memo-row d-flex flex-row flex-nowrap justify-start align-center">
        <div v-for="image in row.images" :key="image.id" class="memo-image-container"
             :style="{'background-color': highlight.includes(image.id) ? '#89bbee' : ''}"
             :class="{'highlight': highlight.includes(image.id)}">
          <v-img :src="$url + '/uploads/images/' + image.path + '.jpg'" class="memo-image"/>
        </div>
      </div>
    </div>

    <!-- Game: recall -->
    <div id="recall" v-if="phase === 'recall'">
      <v-img id="recall-preview"
             :src="resultHighlight !== false ? $url + '/uploads/images/' + recallImages[resultHighlight].path + '.jpg': ''"/>
      <div v-for="image in recallImages" :key="'place-' + image.id" class="recall-place"
           :style="{'top': image.insTop + 'px', 'left': image.insLeft + 'px', 'background-color': input === image.id ? '#336799' : '#ffffff'}"
           @click="clickedPlace(image.id)">
        {{ image.id + 1 }}
      </div>
      <v-img v-for="image in recallImages" :key="'image-' + image.id" class="recall-image"
             :src="$url + '/uploads/images/' + image.path + '.jpg'"
             :style="{'top': image.top + 'px', 'left': image.left + 'px'}"
             @mouseover="resultHighlight = image.id" @mouseout="resultHighlight = false"
             @click.native="clickedImage(image.id)"/>
    </div>

    <!-- Game: result -->
    <div id="result" v-if="phase === 'result'" class="d-flex flex-column flex-nowrap justify-center align-start">
      <div v-for="row in memoRows[page - 1]" :key="page + '-' + row.number"
           class="result-row d-flex flex-row flex-nowrap justify-start align-center">
        <v-img v-for="image in row.images" :key="image.id" class="result-image" :class="[resultCols[image.id].class]"
               @mouseover="resultHighlight = image.id" @mouseleave="resultHighlight = false"
               :src="$url + '/uploads/images/' + (image.id !== resultHighlight ? recall[image.id] : memo[image.id]) + '.jpg'">
        </v-img>
      </div>
    </div>

    <!-- Countdown -->
    <Countdown :title="$t('events.images')" ref="countdown"/>

    <!--Mobile arrows-->
    <MobileArrows v-if="['memo', 'result'].includes(phase) && $vuetify.breakpoint.width <= 930"
                  :on-left="onLeft" :on-right="onRight"/>
    <div v-if="['memo', 'result'].includes(phase) && $vuetify.breakpoint.width <= 930"
         style="height: 60px; width: 100%"></div>

    <!-- Images loader -->
    <div style="height: 100px; position: relative; width: 100px; z-index: -1">
      <v-img v-for="image in memo" :key="image" :src="$url + '/uploads/images/' + image + '.jpg'"
             style="height: 100px; left: 0; position: fixed; top: 0; width: 100px"/>
    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderTraining';
import Pagination from '../components/Pagination';
import Countdown from '../components/Countdown';
import MobileArrows from '../components/MobileArrows';

export default {
  name: 'TrainingImages',
  components: {Header, Pagination, Countdown, MobileArrows},
  data: () => ({
    page: 1, pages: 1, time: 0, score: -1, official: false, live: 0, phase: 'wait', highlight: [], input: 0,
    memo: [], recall: [], order: [], begin: 0,
    memoRows: [], resultCols: [], resultHighlight: false, doubleClickMemoEnd: false,
    recallImages: []
  }),
  computed: {
    params() {
      return this.$api.game.preferences;
    },
    liveTime() {
      if (this.phase === 'memo') return 0;
      else if (this.phase === 'result') return Math.min(this.time, this.params.memo * 1000);
      else return Math.max(-this.time, -this.params.memo * 1000);
    },
    onLeft() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page - 1 > 0) this.onPageChange(this.page - 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 15 >= 15 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else return () => ({});
    },
    onRight() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          if (this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
            this.$refs.header.reset();
          else this.doubleClickMemoEnd = this.highlight[0] + this.params.highlight >= this.params.number;
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 15 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else return () => ({});
    }
  },
  watch: {
    highlight: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    recall: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    time: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    input: {
      handler() {
        if (this.input < 0) this.highlight = [];
        else this.highlight = [this.input];
      }, deep: true
    }
  },
  mounted() {
    this.pages = Math.ceil(this.params.number / 15);
    this.time = this.$api.isGameReadOnly() ? this.$api.game.time : this.$api.isGameLiveNow() ? this.$api.game.time : 0;
    this.score = this.$api.isGameReadOnly() ? this.$api.game.score : this.$api.isGameLiveNow() ? this.$api.game.score : -1;
    this.official = this.$api.game.official;
    this.live = parseInt(this.$api.game.live);
    this.phase = this.$api.isGameReadOnly() ? 'result' : 'wait';
    for (let i = 0; i < this.params.highlight; i++) this.highlight.push(i);
    this.memo = this.$api.game.data;
    this.order = this.$api.game.order;

    if (this.$api.isGameReadOnly() || this.$api.isGameLiveNow())
      this.recall = this.$api.game.recall;
    else for (let i = 0; i < this.params.number; i++) this.recall.push('');

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 15 === 0) this.memoRows.push([]);
      if (i % 5 === 0) this.memoRows[this.memoRows.length - 1].push({number: Math.floor(i / 5) + 1, images: []});
      this.memoRows[this.memoRows.length - 1][this.memoRows[this.memoRows.length - 1].length - 1].images.push({
        id: i, path: this.memo[i], number: i + 1
      });
    }

    for (let i = 0; i < this.memo.length; i++) {
      this.recallImages.push({
        id: i,
        path: this.memo[this.order[i]],
        number: i + 1,
        inserted: -1,
        original: this.order[i]
      });
      this.recallImages[this.recallImages.length - 1].top = this.getBounds(i).top;
      this.recallImages[this.recallImages.length - 1].left = this.getBounds(i).left;
      this.recallImages[this.recallImages.length - 1].insTop = this.getBounds(i, i).top;
      this.recallImages[this.recallImages.length - 1].insLeft = this.getBounds(i, i).left;
    }

    if (!this.$api.isGameReadOnly()) {
      if (this.$api.isGameLiveNow()) {
        let now = new Date().getTime();
        if (now < this.live) {
          this.$refs.countdown.start(new Date().getTime() + this.live - now, false, this.startMemo);
        } else if (now < this.live + this.params.memo * 1000 && this.time === 0) {
          this.startMemo(this.live + this.params.memo * 1000 - now);
          this.begin = this.live;
        } else if (now < this.live + this.params.memo * 1000 + 10000) {
          this.phase = 'wait';
          this.$refs.countdown.start(this.live + this.params.memo * 1000 + 10000, false, this.startRecall);
          this.time = Math.abs(this.time);
        } else if (now < this.live + 1000 * (this.params.memo + 10 + this.params.recall) && this.time < 0) {
          this.startRecall(this.live + this.params.memo * 1000 + 10000 + this.params.recall * 1000 - now);
          this.time = Math.abs(this.time);
        } else this.stopRecall();
      } else this.$refs.countdown.start(new Date().getTime() + 10000, true, this.startMemo);
    } else this.stopRecall();

    document.addEventListener('keydown', this.eventListener);
  },
  beforeDestroy() {
    clearTimeout(this.$refs.countdown.countdown);
    clearTimeout(this.$refs.header.countdown);
    this.$api.game.gid = 'local';
    this.$api.submitGame();
    document.removeEventListener('keydown', this.eventListener);
  },
  methods: {
    clickedImage(id) {
      if (this.recallImages[id].inserted === -1) {
        this.recall[this.input] = this.recallImages[id].path;
        this.recallImages[id].inserted = this.input;
        this.recallImages[id].top = this.getBounds(id, this.input).top;
        this.recallImages[id].left = this.getBounds(id, this.input).left;

        let allPlaces = new Array(this.params.number);
        for (let i = 0; i < this.params.number; i++) allPlaces[i] = i;

        let freePlaces = allPlaces.filter(x => this.recallImages.find(y => y.inserted === x) === undefined).sort((a, b) => a - b);
        let freePlacesAfter = freePlaces.filter((x) => x > this.input).sort((a, b) => a - b);

        if (freePlacesAfter.length > 0) this.input = freePlacesAfter[0];
        else if (freePlaces.length > 0) this.input = freePlaces[0];
        else this.input = -1;
      } else {
        this.input = this.recallImages[id].inserted;
        this.recall[this.input] = '';
        this.recallImages[id].inserted = -1;
        this.recallImages[id].top = this.getBounds(id).top;
        this.recallImages[id].left = this.getBounds(id).left;
      }
    },
    clickedPlace(id) {
      if (this.recallImages.some((image) => image.inserted === id)) return;
      this.input = id;
    },
    eventListener(e) {
      if (this.phase === 'memo') {
        if (e.key === 'ArrowRight' && this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
          this.$refs.header.reset();
        else this.doubleClickMemoEnd = e.key === 'ArrowRight' && this.highlight[0] + this.params.highlight >= this.params.number;

        if (e.key === 'ArrowLeft') {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 15 >= 15 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
        } else if (e.key === 'ArrowRight') {
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 15 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
        } else if (e.key === 'ArrowUp') {
          let next = this.highlight[0] - 5;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 15 >= 10) this.onPageChange(this.page - 1, false);
          }
        } else if (e.key === 'ArrowDown') {
          let next = this.highlight[0] + 5;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 15 < 5) this.onPageChange(this.page + 1, false);
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        }
      }

      if (this.phase === 'recall') {
        if (e.key === 'Enter') {
          this.$refs.header.reset();
        }
      }

      if (e.key === ',' && ['memo', 'result'].includes(this.phase)) {
        e.preventDefault();
        if (this.page - 1 >= 1) this.onPageChange(this.page - 1);
      } else if (e.key === '.' && ['memo', 'result'].includes(this.phase)) {
        e.preventDefault();
        if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
      } else if (e.key === ' ' && ['memo'].includes(this.phase)) {
        e.preventDefault();
        this.onPageChange(1);
      }
    },
    getBounds(id, place = -1) {
      if (this.$vuetify.breakpoint.width > 930) {
        if (place === -1) {
          let top = 242 + Math.floor(id / 10) * 90;
          let left = 12 + (id % 10) * 90;
          return {top, left};
        } else {
          let top = 2 + Math.floor(place / 10) * 70;
          let left = 2 + (place % 10) * 70;
          return {top, left};
        }
      } else {
        if (place === -1) {
          let top = 1230 + Math.floor(id / 3) * 120;
          let left = 2 + (id % 3) * 120;
          return {top, left};
        } else {
          let top = 2 + Math.floor(place / 3) * 120;
          let left = 2 + (place % 3) * 120;
          return {top, left};
        }
      }
    },
    setHighlight(id) {
      if (this.highlight.includes(id)) return;
      let newHighlight = [];
      for (let i = 0; i < this.params.highlight; i++)
        newHighlight.push(this.params.highlight * Math.floor(id / this.params.highlight) + i);
      this.highlight = newHighlight;
    },
    startMemo(total = this.params.memo * 1000) {
      this.phase = 'memo';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopMemo);
      this.begin = new Date().getTime();
    },
    startRecall(total = this.params.recall * 1000) {
      this.input = 0;
      this.phase = 'recall';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopRecall);
    },
    stopMemo() {
      this.phase = 'wait';
      let delay = this.live === 0 ? 10000 : this.live + 10000 + this.params.memo * 1000 - new Date().getTime();
      this.$refs.countdown.start(new Date().getTime() + delay, this.live === 0, this.startRecall);
      this.time = Math.min(Math.round((new Date().getTime() - this.begin) / 10), this.params.memo * 100);
    },
    stopRecall() {
      this.resultHighlight = 1;
      this.onPageChange(1);
      this.setHighlight(0);
      this.phase = 'result';
      let score = 0;
      for (let i = 0; i < this.memo.length; i++) {
        if (this.recall[i] === this.memo[i]) score++;
        if (this.recall[i] === this.memo[i])
          this.resultCols.push({class: 'result-correct'});
        else if (this.recall[i] === '')
          this.resultCols.push({class: 'result-empty'});
        else this.resultCols.push({class: 'result-incorrect'});
      }
      this.score = score;

      this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);

      this.$refs.header.setScore(this.score, Math.min(this.time, 100 * this.params.memo));
      if (!this.$api.isGameReadOnly()) this.$api.submitGame(this.time, this.recall).then(() => {
        this.$store.commit('setMyResults', []);
        this.$api.init().then(() => {
          this.$store.commit('setProfile', this.$api.profile);
          let bestScore = this.$store.state.profile.events['images'].score;
          let bestTime = this.$store.state.profile.events['images'].time;
          if (this.official && this.score === bestScore && this.time === bestTime) this.$store.commit('showAdvancedDialog', {
            title: this.$t('dialogs.personal-best-title'), sections: [{
              title: this.$t('dialogs.personal-best-section-title'),
              icon: 'mdi-trophy-award',
              content: this.$t('dialogs.personal-best-section-content')
            }]
          });
        });
      });
    },
    onPageChange(page, hard = true) {
      this.page = page;
      window.scrollTo({top: 0, behavior: 'auto'});
      setTimeout(() => {
        if (this.phase === 'memo') {
          if (hard) this.setHighlight((page - 1) * 15);
        } else if (this.phase === 'recall') {
          this.input = (page - 1) * 15;
          this.setHighlight(this.input);
          let f = document.getElementById('recall-input-' + (this.input));
          f.focus();
        }
      }, 10);
    }
  }
};
</script>

<style scoped>
#memo {
  margin: 0 auto;
  width: 900px;
}

.memo-row {
  height: 180px;
  width: 900px;
}

.memo-image-container {
  border-radius: 8px;
  height: 180px;
  width: 180px;
}

.memo-image {
  border-radius: 8px;
  height: 170px;
  margin: 5px;
  max-height: 170px;
  max-width: 170px;
  width: 170px;
}

#recall {
  height: 500px;
  margin: 0 auto;
  position: relative;
  width: 900px;
}

#recall-preview {
  box-shadow: 0 0 0 2px #336799;
  border-radius: 8px;
  height: 176px;
  left: 710px;
  position: absolute;
  top: 10px;
  width: 176px;
}

.recall-image {
  border-radius: 4px;
  cursor: pointer;
  height: 66px;
  max-height: 170px;
  max-width: 170px;
  position: absolute;
  transition-duration: 200ms;
  width: 66px;
}

.recall-place {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #336799;
  border-radius: 4px;
  color: rgb(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: 20px;
  height: 66px;
  padding-top: 16px;
  position: absolute;
  text-align: center;
  width: 66px;
}

#result {
  margin: 0 auto;
  width: 900px;
}

.result-row {
  height: 180px;
  width: 900px;
}

.result-image {
  border-radius: 8px;
  height: 170px;
  margin: 5px;
  max-height: 170px;
  max-width: 170px;
  width: 170px;
}

.result-correct {
  border: 3px solid #4CAF50;
}

.result-incorrect {
  border: 3px solid #E53935;
}

.result-empty {
  border: 3px solid #336799;
}

.result-incorrect:hover, .result-empty:hover {
  border: 3px dotted #4CAF50;
}

@media screen and (max-width: 930px) {
  #memo, #result {
    width: 100%;
  }

  .memo-row, .result-row {
    flex-wrap: wrap !important;
    height: auto;
    margin: 0 auto;
    width: 360px;
  }

  .memo-image-container {
    height: 344px;
    margin: 0 8px;
    width: 344px;
  }

  .memo-image {
    height: 334px;
    max-height: 334px;
    max-width: 334px;
    width: 334px;
  }

  .result-image {
    height: 334px;
    margin: 5px 10px;
    max-height: 334px;
    max-width: 334px;
    transition-duration: 500ms;
    width: 334px;
  }

  #recall {
    height: 2450px;
    width: 360px;
  }

  #recall-preview {
    display: none;
  }

  .recall-image {
    height: 116px;
    width: 116px;
  }

  .recall-place {
    font-size: 26px;
    height: 116px;
    padding-top: 37px;
    width: 116px;
  }
}
</style>
